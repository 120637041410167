<template>
  <div class="volunteer">
     <div class="volunteer-continar">
     <Redribbon></Redribbon>
     <Workinfo :list="worklist" :worktitle="'青少年工作信息'"></Workinfo>
     <!-- <Activityvideo :videolist="videolist" :node="type"></Activityvideo> -->
    </div>
  </div>
</template>

<script>
import Workinfo from '../Volunteer/components/workinfo'
import Redribbon from './components/redribbon'
import Activityvideo from '../Volunteer/components/activityvideo'
import apiUrls from '@api'
export default {
  components: { 
   Redribbon,
   Workinfo,
   Activityvideo 
   },
  name: 'Volunteer',
  data () {
    return {
      worklist: [],
      videolist: [],
	  type:'zyzfwhszqsn_hdsp'
    }
  },
  created () {
    this.getworklist()
    this.getvideolist() 
  },
  methods : {
    getworklist() {
      apiUrls.getvolunteer({node: 'zyzfwhszqsn_gzxx'}).then(res=>{
      this.worklist= res.results.data
      })
    },
    getvideolist() {
      apiUrls.getvolunteer({node: 'zyzfwhszqsn_hdsp'}).then(res=>{
      this.videolist= res.results.data
      }) 
    }
  }
}
</script>

<style lang="less" scoped>
.volunteer {
  background-color: #f5f5f5;
    .volunteer-continar{
        width: 1200px;
        min-height: 400px;
        padding-bottom: 40px;
        margin: 0 auto;
        background-color: #f5f5f5;
        &::before {
            display: block;
            content: ".";
            clear: both;
            height: 0;
            overflow: hidden;
            visibility: hidden;
        }
    }
}
</style>
