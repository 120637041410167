<template>
<div>
<div class="redtitle">红十字<i class="pointer"></i>青少年</div>
  <div class="projectintroduct">
    <div class="bg-redribbon">
      <div class="titlelogo"></div>
    <div class="title">{{introduce.title || ""}}</div>
     <TitleLine width="180px"></TitleLine>
    <div class="introductcontent">
       <p v-html="introduce.info"></p>
    </div>
    </div>
  </div>
</div>
</template>

<script>
import TitleLine  from '@/components/TitleLine'
import apiUrls from '@api'
export default {
  components: {
    TitleLine
  },
  name: 'Projectintroduct',
  data() {
    return {
     introduce:{}
    }
  },
  filters: {
    addpointer (val) {
      let pointer =  val.split("")
    }
  },
  created () {
    this.getintroduct()
  },
  methods: {
    getintroduct() {
      apiUrls.getintroduce({node: 'hszqsnjj'}).then(res=>{
        console.log('红十字青年简介', res.results.data)
      this.introduce= res.results.data
      let pointer = this.introduce.title.split('')
      }) 
    }
  }
}
</script>

<style lang="less" scoped>
.redtitle  {
    margin-top: 10px;
    font-size: 23px;
    color: #333333;
    font-weight: bold;
    display: flex;
    margin-bottom: 16px;
    .pointer{
      width: 6px;
      height: 6px;
      border-radius: 50%;
      position: relative;
      top: 10px;
      margin: 0 5px;
      background-color: #333333;
    }
    &::before {
        content: "";
        display: block;
        height: 20px;
        width: 20px;
        background: url('../../../assets/img/title.png') no-repeat center center;
    }
}
.projectintroduct {
    height: 750px;
    background-color: #ffffff;
    overflow: hidden;
    box-shadow: 0px 0px 18px 0px rgba(14,14,14, 0.13);
    .bg-redribbon {
     width: 100%;
     height: 90%;
     background: url('../../../assets/img/bg-teenagers.png') no-repeat 10px 130px;
     background-size: 490px 476px;
    }
    .titlelogo {
        width: 77px;
        height: 75px;
        margin: 0 auto;
        margin-top: 39px;
        margin-bottom: 17px;
        background: url('../../../assets/img/title-teenagers.png') no-repeat center center;
    }
    .title {
      font-size: 24px;
      font-weight: bolder;
      color: #555454;
      text-align: center;
    }
    .division {
      text-align: center;
    }
    .introductcontent {
        width: 957px;
        min-height: 449px;
        // border: 1px solid #666666;
        margin: 0 auto;
        margin-top: 36px;
        p {
            text-indent: 2em;
            font-size: 16px;
            color: #333333;
            line-height: 36px;
        }
    }
}
</style>